import { Components, createTheme } from '@mui/material';
import { PALETTE } from '../palette';
import { PRIMARY_FONT_REG } from '../typography';
import { pxToRem } from '../../../shared/utils/helpers';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // Custom Variants here
  }
}

const theme = createTheme();

export const BUTTON_BASE: Components['MuiButtonBase'] = {
  defaultProps: {
    disableRipple: true,
  },
};

export const BUTTON: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      fontFamily: PRIMARY_FONT_REG,
      fontWeight: 400,
      textTransform: 'capitalize',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      maxWidth: '300px',
      borderRadius: '10px',
      boxShadow: 'none',
      lineHeight: 1,
      outline: 0,
    },
    contained: {
      backgroundColor: PALETTE.primary.main,
      '&:hover': {
        backgroundColor: PALETTE.primary.main,
        boxShadow: PALETTE.boxShadows.shadowPrimary,
      },
      '&:disabled': {
        color: PALETTE.text.light,
        backgroundColor: PALETTE.utility.light,
      },
      '&.MuiLoadingButton-loading': {
        '&:disabled': {
          color: 'transparent',
          backgroundColor: PALETTE.primary.main,
        },
      },
      '.MuiLoadingButton-loadingIndicator': {
        color: PALETTE.primary.contrastText,
      },
    },
    containedSecondary: {
      backgroundColor: PALETTE.secondary.main,
      color: PALETTE.secondary.contrastText,
      '&:hover': {
        backgroundColor: PALETTE.secondary.dark,
        boxShadow: PALETTE.boxShadows.shadowSecondary,
      },
      '&:disabled': {
        color: PALETTE.text.light,
        backgroundColor: PALETTE.utility.light,
      },
    },
    outlined: {
      borderWidth: '2px',
      borderColor: PALETTE.primary.main,
      backgroundColor: PALETTE.common.white,
      '&:hover': {
        borderWidth: '2px',
        backgroundColor: PALETTE.common.white,
        boxShadow: PALETTE.boxShadows.shadowPrimary,
      },
      '&:disabled': {
        color: PALETTE.text.light,
        border: `2px solid ${PALETTE.utility.light}`,
      },
    },
    text: {
      border: 0,
    },
    sizeSmall: {
      fontSize: '12px',
      fontWeight: 400,
      padding: '5px 15px',
      maxWidth: '150px',
      [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(8), 
            padding: '4px 12px',

          },
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(10), 
          },
    },
    sizeMedium: {
      fontSize: '18px',
      fontWeight: 400,
      padding: '8px 16px',
      maxWidth: '200px',
      [theme.breakpoints.down('sm')]: {
        fontSize: pxToRem(14), 
        padding: '4px 12px',

      },
      [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(16), 
      },
    },
    sizeLarge: {
      fontSize: '24px',
      fontWeight: 700,
      padding: '14px 30px',
      [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(18), 
          },
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(20), 
          },
    },
  },
};


export const ICON_BUTTON: Components['MuiIconButton'] = {
  styleOverrides: {
    root: {
      fontSize: '50px',
      textAlign: 'center',
      width: '40px',
      height: '40px',
      padding: '4px 0 0 4px',
      transition: 'all ease-in 0.2s',
      backgroundColor: PALETTE.common.white,
      '&:hover': {
        boxShadow: PALETTE.boxShadows.shadowPrimary,
        backgroundColor: PALETTE.common.white,
      },
      '&:disabled': {
        color: PALETTE.text?.secondary,
        backgroundColor: PALETTE.utility.light,
      },
    },
    colorPrimary: {
      border: `2px solid ${PALETTE.primary.main}`,
    },
    sizeSmall: {
      minWidth: '30px',
      width: '32px',
      height: '32px',
    },
    sizeMedium: {
      '.MuiButton-icon > .MuiSvgIcon-root': {
        width: '18px',
      },
    },
  },
};
