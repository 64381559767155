import { TypographyVariantsOptions, createTheme } from '@mui/material';
import { pxToRem } from '../../shared/utils/helpers';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export const PRIMARY_FONT_REG = 'Arial, Helvetica, sans-serif';
export const PRIMARY_FONT_BOLD = 'Arial, Helvetica, sans-serif';

const theme = createTheme();

export const TYPOGRAPHY: TypographyVariantsOptions = {
  fontFamily: PRIMARY_FONT_REG,
  h1: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(60),
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(30),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(40),
    },
  },
  h2: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(48),
    lineHeight: 1.3,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(20),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(28),
    },
  },
  h3: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(32),
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(16),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(20),
    },
  },
  h4: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(24),
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(18),
    },
  },
  h5: {
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(12),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(14),
    },
  },
  //Used for logo
  h6: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(24),
    lineHeight: 0,
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: pxToRem(26),
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16),
    },
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: pxToRem(24),
    lineHeight: 1.4,
  },
  body1: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(10),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(12),
    },
  },
  body2: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(8.5),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(10.5),
    },
  },
  body3: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(9),
    lineHeight: 1.4,
  },
  button: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: 1.75,
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(8),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(10),
    },
  },
  overline: {
    fontFamily: PRIMARY_FONT_BOLD,
    fontWeight: 700,
    fontSize: pxToRem(11),
    lineHeight: 1,
    textTransform: 'uppercase',
  },
};
