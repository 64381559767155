import { Box, Grid2 as Grid, Stack, Typography, useTheme } from '@mui/material';
import { CaInterface } from '../utils/types';

const ChallengesVsAdvantages = ({
  advantages,
  challenges,
  imgSrc,
  story,
}: {
  advantages: CaInterface;
  challenges: CaInterface;
  imgSrc: string;
  story: any;
}) => {
  const theme = useTheme();

  return (
    <Box bgcolor={theme.palette.common.white} borderRadius="50px">
      <Grid container spacing={2} padding={{ xs: 2, sm: 4 }}>
        <Grid size={{ xs: 12, sm: 6 }} alignItems="center" sx={{ justifyContent: 'space-between' }}>
          <Stack padding={{ xs: 2, sm: 4 }} sx={{ flex: 1, alignItems: 'center' }}>
            <Typography variant="h3" gutterBottom>
              {challenges.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {challenges.one}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {challenges.two}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {challenges.three}
            </Typography>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} alignItems="center">
          <Stack padding={{ xs: 2, sm: 4 }} sx={{ flex: 1, alignItems: 'center' }}>
            <Typography variant="h3" gutterBottom color={theme.palette.primary.main}>
              {advantages.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom color={theme.palette.primary.main}>
              {advantages.one}
            </Typography>
            <Typography variant="subtitle1" gutterBottom color={theme.palette.primary.main}>
              {advantages.two}
            </Typography>
            <Typography variant="subtitle1" gutterBottom color={theme.palette.primary.main}>
              {advantages.three}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 3 }}
          padding={{ xs: 2, sm: 4 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            src={imgSrc}
            alt={imgSrc}
            sx={{
              width: 'auto',
              maxHeight: { xs: '200px', sm: '300px' },
              objectFit: 'cover',
            }}
          />
        </Grid>
        <Grid
          size={{ xs: 12, sm: 9 }}
          padding={{ xs: 2, sm: 4 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1" textAlign="center" justifyContent="center" alignItems="center">
            {story}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChallengesVsAdvantages;
