import React from 'react';
import { Box } from '@mui/material';

import Google from '../../assets/icons/google-play-badge-logo-svgrepo-com.svg';
import Apple from '../../assets/icons/download-on-the-app-store-apple-logo-svgrepo-com.svg';
import Huawei from '../../assets/icons/AppGallery.png';

interface LogoProps {
  height?: string;
  width?: string;
  variant: string;
}

const playStoreLink = 'https://play.google.com/store/apps/details?id=com.hula.parent.app&pcampaignid=web_share';
const iOsStoreLink = 'https://apps.apple.com/za/app/hula-parent/id6469781680';
const huaweiLink = 'https://appgallery.huawei.com/app/C110369185';

const AppLogos: React.FC<LogoProps> = ({ width = '180px', height = 'auto', variant }) => {
  const appLogoWidths = { xs: '120px', sm: '150px', md: width, lg: '220px' };
  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding="20px">
      {variant === 'Google' && (
        <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={Google}
            alt="Download on Google Play"
            sx={{
              maxWidth: appLogoWidths,
              maxHeight: height,
              cursor: 'pointer',
            }}
          />
        </a>
      )}
      {variant === 'Apple' && (
        <a href={iOsStoreLink} target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={Apple}
            alt="Download on Apple IOS"
            sx={{
              maxWidth: appLogoWidths,
              maxHeight: height,
              cursor: 'pointer',
            }}
          />
        </a>
      )}
      {variant === 'Huawei' && (
        <a href={huaweiLink} target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={Huawei}
            alt="Download on Huawei App Gallery"
            sx={{
              maxWidth: appLogoWidths,
              maxHeight: height,
              cursor: 'pointer',
            }}
          />
        </a>
      )}
    </Box>
  );
};

export default AppLogos;
