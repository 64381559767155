import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material';
import { theme } from '../../app/themes/theme';
import { IconTextGridType } from '../utils/types';

const IconTextGrid = ({ items, spacing = 2 }: { items: IconTextGridType; spacing: number }) => {
  const imageWidthHeight = { xs: '30px', md: '40px', lg: '80px', xl: '100px' };

  return (
    <>
      <Grid
        container
        spacing={spacing}
        sx={{ justifyContent: 'space-between', padding: { xs: 6, sm: 2, md: 4, lg: 5 } }}
      >
        {items.map((item, index) => (
          <Grid size={{ xs: 12, sm: spacing === 2 ? 6 : 4 }} key={index}>
            <Stack key={index} textAlign="center" alignItems="center" sx={{ flex: 1, padding: { xs: 1, md: 3 } }}>
              <Box
                sx={{
                  display: 'flex',
                  background: theme.palette.primary.main,
                  marginBottom: '40px',
                  width: { xs: '60px', md: '80px', lg: '120px', xl: '160px' },
                  height: { xs: '60px', md: '80px', lg: '120px', xl: '160px' },
                  borderRadius: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  component="img"
                  src={item.icon}
                  alt={item.icon}
                  sx={{
                    width: imageWidthHeight,
                    height: imageWidthHeight,
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Typography variant="h4" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="body1" color="secondary" textAlign="center">
                {item.text}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default IconTextGrid;
