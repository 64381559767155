import { Box } from '@mui/material';
import Logo from '../../assets/media/HulaTechLogo.svg';
import LogoWhite from '../../assets/media/HulaTechLogoWhite.svg';

interface HulaTechLogoProps {
  height?: string;
  width?: string;
  variant?: 'white' | 'default';
}

const HulaTechLogo: React.FC<HulaTechLogoProps> = ({ width = '120px', height = 'auto', variant }) => {
  return (
    <Box
      component="img"
      src={variant === 'white' ? LogoWhite : Logo}
      alt="Hula Technology"
      sx={{ height, maxWidth: { xs: '90px', sm: '110px', md: width, lg: '160px' }, objectFit: 'contain' }}
    />
  );
};

export default HulaTechLogo;
