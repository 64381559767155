import { Box, Grid2 as Grid, Typography } from '@mui/material';

const RolesIntroSection = ({ img, title, text }: { img: string; title: string; text: any }) => {
  // const imageWidthHeight = { xs: '100px', md: '140px', lg: '180px', xl: '200px' };

  return (
    <Grid container spacing={4} padding={{ xs: 2, md: 4 }}>
      <Grid size={{ xs: 12, md: 5 }} display="flex" alignItems="center" justifyContent="center">
        <Box
          component="img"
          src={img}
          alt="No Image"
          sx={{
            maxWidth: '90%',
            maxHeight: { xs: '200px', sm: '300px' },
            objectFit: 'cover',
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 7 }} px={{ xs: 2, sm: 4 }}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1">{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default RolesIntroSection;
