import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const LINK: Components['MuiLink'] = {
  styleOverrides: {
    root: {
      color: PALETTE.primary.light,
      textUnderlineOffset: '4px',
      transition: 'border 0.3s'
    }
  }
};
