import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { analytics, firebaseLogEvent as logEvent } from '../../shared/services/firebase';
import { useAnalytics } from '../../shared/hooks/useAnalytics';

function TheContent() {
  // const location = useLocation();
  useAnalytics();
  // useEffect(() => {
  //   if (analytics) {
  //     logEvent(analytics, 'page_view', { page_path: location.pathname });
  //   }
  // }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default React.memo(TheContent);
