import React, { useState } from 'react';
import { Box, Button, Grid2 as Grid, TextField, Typography, Snackbar, Stack } from '@mui/material';
import { Facebook, LinkedIn, Instagram } from '@mui/icons-material';
import { AppLogos, LoadingSpinner } from '../../shared/components';
import { theme } from '../../app/themes';
import { btnSecondaryStyle } from '../utils/constants';
import emailjs from '@emailjs/browser';

interface FormData {
  [key: string]: string;
  name: string;
  email: string;
  subject: string;
  message: string;
}

const iconsSx = {
  fontSize: { xs: '3rem', md: '5rem' },
  color: theme.palette.primary.main,
};

const textFieldSx = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    fontSize: '24px',
    height: '60px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '18px',
  },
};

const GetInTouch: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);
    emailjs
      .send(
        `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, // EmailJS Service ID
        `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`, // EmailJS Template ID
        formData, // Email data
        `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}` // EmailJS Public Key
      )
      .then(
        () => {
          setSnackbarOpen(true);
          setFormData({ name: '', email: '', subject: '', message: '' });
        },
        (error) => {
          console.error('Failed to send email:', error.text);
        }
      )
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <Box padding="40px">
      <Stack display="flex" alignItems="center">
        <Typography variant="h3" gutterBottom textAlign="center">
          Get in touch
        </Typography>
        <Typography variant="body1" gutterBottom justifyContent="space-around">
          <strong>
            Facing resource challenges? Struggling to keep parents engaged? Overwhelmed by an administrative backlog?
          </strong>
        </Typography>
        <Typography variant="body1">
          <strong>Hula is here</strong> to help you overcome these hurdles.Let’s work together to streamline your
          processes, <br /> improve parent-teacher collaboration, and support your students more effectively.
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 800, maxHeight: 800, mx: 'auto', mt: 4, p: 3, borderRadius: '40px' }}
        bgcolor={theme.palette.primary.main}
      >
        <Stack spacing={3}>
          <Typography variant="h4" gutterBottom>
            Send us an email
          </Typography>

          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            sx={textFieldSx}
          />

          <TextField
            label="Your Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
            sx={textFieldSx}
          />

          <TextField
            label="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            fullWidth
            required
            sx={textFieldSx}
          />

          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            multiline
            rows={6}
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                fontSize: '24px',
              },

              '& .MuiInputLabel-root': {
                fontSize: '18px',
              },
            }}
          />

          {!isSending ? (
            <Button type="submit" variant="contained" size="medium" color="secondary" sx={btnSecondaryStyle}>
              Send Message
            </Button>
          ) : (
            <Stack position="relative">
              <LoadingSpinner color={theme.palette.secondary.light} />
            </Stack>
          )}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={(event, reason) => {
              if (reason === 'clickaway') return;
              setSnackbarOpen(false);
            }}
            message="Message sent successfully!"
          />
        </Stack>
      </Box>
      <Grid
        container
        spacing={{ xs: 1, md: 3 }}
        pt={{ xs: 4, md: 6 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid size={12} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2">Download the Apps</Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <AppLogos variant="Google" />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <AppLogos variant="Apple" />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <AppLogos variant="Huawei" />
        </Grid>
      </Grid>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid size={12} display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h3">Connect with us</Typography>
        </Grid>
        <Grid>
          <a href={'https://www.facebook.com/profile.php?id=61551471058333'} target="_blank" rel="noopener noreferrer">
            <Facebook sx={iconsSx} />
          </a>
        </Grid>
        <Grid>
          <a
            href={'https://www.instagram.com/hula.tech?igsh=MWNrNGo4Z201eGEwag=='}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram sx={iconsSx} />
          </a>
        </Grid>
        <Grid>
          <a
            href={'https://www.linkedin.com/company/hula-technology/?viewAsMember=true'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn sx={iconsSx} />
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GetInTouch;
