import { Components } from '@mui/material';
import {
  ACCORDION,
  ACCORDION_DETAILS,
  BUTTON,
  BUTTON_BASE,
  CARD,
  CHIP,
  CHECKBOX,
  DIALOG,
  DIALOG_ACTIONS,
  DIALOG_CONTENT,
  DIALOG_TITLE,
  DIVIDER,
  FORM_CONTROL_LABEL,
  FORM_HELPER_TEXT,
  ICON_BUTTON,
  INPUT_LABEL,
  LINK,
  LIST_ITEM_BUTTON,
  LIST_ITEM_ICON,
  LIST_ITEM_TEXT,
  MENU,
  MENU_ITEM,
  MENU_LIST,
  OUTLINED_INPUT,
  TOOLTIP,
} from './overrides';

export const COMPONENTS: Components = {
  MuiAccordion: ACCORDION,
  MuiAccordionDetails: ACCORDION_DETAILS,
  MuiButtonBase: BUTTON_BASE,
  MuiButton: BUTTON,
  MuiCard: CARD,
  MuiChip: CHIP,
  MuiCheckbox: CHECKBOX,
  MuiDialog: DIALOG,
  MuiDialogActions: DIALOG_ACTIONS,
  MuiDialogContent: DIALOG_CONTENT,
  MuiDialogTitle: DIALOG_TITLE,
  MuiDivider: DIVIDER,
  MuiFormControlLabel: FORM_CONTROL_LABEL,
  MuiFormHelperText: FORM_HELPER_TEXT,
  MuiIconButton: ICON_BUTTON,
  MuiInputLabel: INPUT_LABEL,
  MuiLink: LINK,
  MuiListItemButton: LIST_ITEM_BUTTON,
  MuiListItemIcon: LIST_ITEM_ICON,
  MuiListItemText: LIST_ITEM_TEXT,
  MuiMenu: MENU,
  MuiMenuItem: MENU_ITEM,
  MuiMenuList: MENU_LIST,
  MuiOutlinedInput: OUTLINED_INPUT,
  MuiTooltip: TOOLTIP,
};
