import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

interface CustomPalettes {
  utility: SimplePaletteColorOptions;
  modal: {
    modalUploadGradient: string;
  };
  boxShadows: {
    shadowPrimary: string;
    shadowPrimaryLight: string;
    shadowUtility: string;
  };
}

declare module '@mui/material/styles' {
  interface PaletteOptions extends CustomPalettes {
    modal: {
      modalUploadGradient: string;
    };
    boxShadows: {
      shadowPrimary: string;
      shadowPrimaryLight: string;
      shadowUtility: string;
    };
  }
}
declare module '@mui/material/styles/createPalette' {
  interface Palette extends CustomPalettes {
    modal: {
      modalUploadGradient: string;
    };
    boxShadows: {
      shadowPrimary: string;
      shadowPrimaryLight: string;
      shadowUtility: string;
    };
  }
}

export const PALETTE: any = {
  primary: {
    main: '#FFAA1D',
    light: '#FFBD50',
    dark: '#E99100',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#0B2F4E',
    light: '#114A7B',
    dark: '#051421',
    contrastText: '#ffffff'
  },
  error: {
    main: '#cc3333',
    light: '#D18585',
    dark: '#800000',
    contrastText: '#ffffff'
  },
  warning: {
    main: '#FF8000',
    light: '#ffd9b2',
    dark: '#b35900',
    contrastText: '#ffffff'
  },
  info: {
    main: '#008dc8',
    light: '#B6E3F5',
    dark: '#00539f',
    contrastText: '#ffffff'
  },
  success: {
    main: '#008800',
    light: '#428942',
    dark: '#005200',
    contrastText: '#ffffff'
  },
  utility: {
    main: '#DFDFDF',
    light: '#F8F8F8',
    dark: '#939393'
  },
  text: {
    primary: '#0B2F4E',
    secondary: '#FFF',
    disabled: '#333333',
    black:'#000'
  },
  modal: {
    modalUploadGradient: 'linear-gradient(0deg, rgba(0,83,159,1) 0%, rgba(0,141,200,1) 100%)'
  },
  boxShadows: {
    shadowPrimary: `0 0 0 4px rgba(0, 83, 159, 0.4)`,
    shadowPrimaryLight: `0 0 0 4px rgba(0, 141, 200, 0.4)`,
    shadowUtility: `0 0 0 4px rgba(234, 234, 234, 0.4)`,
    shadowError: `0 0 0 4px rgba(204,51,51, 0.4)`
  },
  background: {
    paper: '#ffffff',
    default: '#f6f6f6'
  },
  common: {
    black: '#000000',
    white: '#ffffff'
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161'
  }
};
