import { useNavigate } from 'react-router-dom';
import { Grid2 as Grid, styled, Typography } from '@mui/material';
import { GetInTouch, HulaTechLogo } from '../../shared/components';
import { theme } from '../../app/themes/theme';
import { companyLinks, exploreLinks, quickLinks, routeKeys } from '../../shared/utils/constants';

const FooterContainer = styled('div')(() => ({
  backgroundColor: theme.palette.primary.main,
  bottom: '0',
  width: '100%',
  height: '200px',
}));

const QuickLinkList = styled('ul')(() => ({
  width: '100%',
  height: 'auto',
}));
const QuickLinkListItems = styled('li')(() => ({
  width: '100%',
  height: 'auto',
  cursor: 'pointer',
}));

const TheFooter = () => {
  const navigate = useNavigate();

  return (
    <>
      <GetInTouch />
      <FooterContainer>
        <Grid container spacing={{ xs: 1, md: 3 }}>
          <Grid size={{xs:2, md:3}} padding={{ xs: 1, md: 3 }}>
            <HulaTechLogo variant="white" />
          </Grid>
          <Grid size={{xs:3.33, md:3}}>
            <QuickLinkList>
              <Typography variant="h4">Explore</Typography>
              {exploreLinks.map((item, index) => (
                <QuickLinkListItems key={index} onClick={() => navigate(item.path)}>
                  {item.text}
                </QuickLinkListItems>
              ))}
            </QuickLinkList>
          </Grid>
          <Grid size={{xs:3.33, md:3}}>
            <QuickLinkList>
              <Typography variant="h4">Quick Links</Typography>
              {quickLinks.map((item, index) => (
                <QuickLinkListItems key={index} onClick={() => navigate(item.path)}>
                  {item.text}
                </QuickLinkListItems>
              ))}
            </QuickLinkList>
          </Grid>
          <Grid size={{xs:3.33, md:3}}>
            <QuickLinkList>
              <Typography variant="h4">Company</Typography>
              {companyLinks.map((item, index) => (
                <QuickLinkListItems key={index} onClick={() => navigate(item.path)}>
                  {item.text}
                </QuickLinkListItems>
              ))}
            </QuickLinkList>
          </Grid>
        </Grid>
      </FooterContainer>
    </>
  );
};

export default TheFooter;
