import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const DIALOG: Components['MuiDialog'] = {
  styleOverrides: {
    root: {},
    paper: {
      borderRadius: '0',
      boxShadow: PALETTE.boxShadows.shadowUtility
    }
  }
};

export const DIALOG_TITLE: Components['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      fontSize: '20px',
      color: PALETTE.primary.main,
      padding: '30px 20px 15px 20px',
      marginBottom: '10px'
    }
  }
};

export const DIALOG_CONTENT: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      fontSize: '14px',
      color: PALETTE.text.primary,
      padding: '30px 20px 15px 20px',
      minWidth: '440px',
      '.MuiDialogContentText-root': {
        color: PALETTE.text.primary
      }
    }
  }
};

export const DIALOG_ACTIONS: Components['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      borderTop: `1px solid ${PALETTE.utility.light}`,
      padding: '10px 20px'
    }
  }
};
