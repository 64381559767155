import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { Menu as MenuIcon, KeyboardArrowDown } from '@mui/icons-material';
import { HulaTechLogo } from '../../shared/components';
import { theme } from '../../app/themes/theme';
import { routeKeys } from '../../shared/utils/constants';
import { useAnalytics } from '../../shared/hooks/useAnalytics';

const TheHeader = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackClick } = useAnalytics();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const menuOptions = [
    { title: 'Teacher', path: routeKeys.teachers },
    { title: 'Schools', path: routeKeys.admins },
    { title: 'Parents', path: routeKeys.parents },
    { title: 'Students', path: routeKeys.students },
  ];

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(true);
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuOpen(false);
    setMenuAnchor(null);
  };
  const handleMenuItemClick = (path: string) => {
    handleCloseMenu();
    setMenuOpen(false);
    setMenuAnchor(null);
    navigate(path);
  };

  const handleClickMobileMenu = (path: string) => {
    setDrawerOpen(false);
    navigate(path);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: theme.palette.common.white, padding: '10px' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Logo */}
        <HulaTechLogo />

        {/* Mobile Drawer Menu */}
        {isMobile ? (
          <>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <List sx={{ width: 250 }}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleClickMobileMenu(routeKeys.home)}>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </ListItem>

                {menuOptions.map((item) => (
                  <ListItem disablePadding key={item.path}>
                    <ListItemButton onClick={() => handleClickMobileMenu(item.path)}>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleClickMobileMenu(routeKeys.aiTools)}>
                    <ListItemText primary="AI Tools" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleClickMobileMenu(routeKeys.aboutUs)}>
                    <ListItemText primary="About Us" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      window.open('https://www.ailab.hulatechnology.com', '_blank');
                      setDrawerOpen(false);
                      trackClick('AI Lab');
                    }}
                  >
                    <ListItemText primary="AI Lab" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      window.open('https://schoolportal.hulatechnology.com', '_blank');
                      setDrawerOpen(false);
                      trackClick('Login School Portal');
                    }}
                  >
                    <ListItemText primary="Login" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          </>
        ) : (
          // Desktop Menu
          <>
            <Button variant="text" color="secondary" onClick={() => navigate(routeKeys.home)}>
              Home
            </Button>
            <Button variant="text" color="secondary" onClick={handleOpenMenu} endIcon={<KeyboardArrowDown />}>
              Roles in education
            </Button>
            {menuOpen && (
              <Menu anchorEl={menuAnchor} open={menuOpen} onClose={() => handleCloseMenu()}>
                {menuOptions.map((item) => (
                  <MenuItem key={item.path} onClick={() => handleMenuItemClick(item.path)}>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            )}
            <Button variant="text" color="secondary" onClick={() => navigate(routeKeys.aiTools)}>
              AI Tools
            </Button>
            <Button variant="text" color="secondary" onClick={() => navigate(routeKeys.aboutUs)}>
              About Us
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                window.open('https://www.ailab.hulatechnology.com', '_blank');
                trackClick('AI Lab');
              }}
            >
              AI Lab
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                window.open('https://schoolportal.hulatechnology.com/', '_blank');
                trackClick('Login School Portal');
              }}
            >
              Login
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TheHeader;
