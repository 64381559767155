import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const CARD: Components['MuiCard'] = {
  styleOverrides: {
    root: {
      border: `1px solid ${PALETTE.utility.main}`,
      borderRadius: 0,
      boxShadow: PALETTE.boxShadows.shadowUtility,
      transition: 'all ease 0.4s',
      minWidth: '280px',
      overflow: 'none',
      '&:hover': {
        borderColor: PALETTE.primary.main,
        boxShadow: PALETTE.boxShadows.shadowPrimaryLight,
        transform: 'scale(1.01)'
      }
    }
  }
};
