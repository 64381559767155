import { Components } from '@mui/material';
import { PALETTE } from '../palette';

export const TOOLTIP: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      fontSize: '12px',
      color: PALETTE.text.primary,
      backgroundColor: PALETTE.common.white,
      border: `1px solid ${PALETTE.primary.light}`,
      borderRadius: 0,
      boxShadow: `0px 4px 6px ${PALETTE.grey[400]}`
    },
    arrow: {
      color: PALETTE.primary.light
    }
  }
};
