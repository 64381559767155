import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics, firebaseLogEvent as logEvent } from '../services/firebase';

export const useAnalytics = () => {
  const location = useLocation();
  const [startTime, setStartTime] = useState<number | null>(null);

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', { page_path: location.pathname });
    }

    setStartTime(Date.now());

    return () => {
      if (startTime && analytics) {
        const duration = Date.now() - startTime;
        logEvent(analytics, 'time_spent', { page_path: location.pathname, duration });
      }
    };
  }, [location]);

  // Function to track button clicks
  const trackClick = (buttonName: string) => {
    if (analytics) {
      logEvent(analytics, 'button_click', { button_name: buttonName });
    }
  };

  return { trackClick };
};
