declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const BREAKPOINTS: any = {
  values: {
    xs: 0,
    sm: 640,
    md: 1024,
    lg: 1400,
    xl: 1600,
    xxl: 2000,
  },
};
